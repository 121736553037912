import * as pages from './pages';

/* Each KEY comes from routes.json and each VALUE comes from
the getContent.js file, imported inside ./pages as well */
export const cmsPageKeyByRouteKey = {
  ACCOUNT: 'accountPage',
  ANALYSIS: 'analysisPage',
  ASSEMBLY: 'assemblyPage',
  BILL: 'billPage',
  BUSINESS_ACCOUNT: 'businessAccountPage',
  BUSINESS_ACCOUNT_REVAMP: 'businessAccountPageRevamp',
  BUSINESS_CARD: 'businessCardPageNew',
  CARD: 'cardPage',
  CONTACT: 'contactPage',
  CONTRACT: 'contractPage',
  DEBIT: 'requestDebitPage',
  EMAILOPTOUT: 'emailOptOutPage',
  EMAIL_VERIFICATION: 'emailVerificationPage',
  ETHIC_AND_COMPLIANCE: 'ethicAndCompliancePage',
  FAQ: 'faqPage',
  FINANCIAL_STATEMENTS: 'financialStatementsPage',
  HOME: 'homePage',
  INSURANCE: 'lifeInsurancePage',
  INSURANCE_MOBILE: 'mobileInsurancePage',
  INSTALLMENT: 'revolvingAndInstallmentPage',
  INVESTMENTS: 'investmentsPage',
  LENDING: 'lendingPage',
  LENDING_NU: 'lendingNuPage',
  NEWSLETTER_OPTOUT: 'newsletterOptOutPage',
  EMAIL_SATISFACTION: 'emailSatisfactionSurveyPage',
  MULTI_PRODUCT_REQUEST: 'requestNuPage',
  NUTAP: 'nutapPage',
  PIX: 'pixPage',
  SEGURANCA: 'securityPage',
  MUNDONU: 'nuWorld',
  PJ_LANDING_MGM: 'mgmPjLandingPage',
  PLD: 'pldPage',
  PREPAYMENT: 'prepaymentPage',
  REGULATORY_REQUEST: 'regulatoryRequestPage',
  SCR: 'scrPage',
  SECURED_CARD: 'securedCardPage',
  SUPPLIERS: 'suppliersPage',
  TRANSPARENTE: 'transparentPage',
  ULTRAVIOLET: 'ultravioletaPage',
  UV_LANDING_MGM: 'referralUltravioletPage',
  NEW_UV: 'ultravioletaNewPage',
  TAP_TO_PAY_IPHONE: 'nutapIphonePage',
  UV_LANDING_REQUEST: 'requestUltravioletNewPage',
  UV_LANDING_REQUEST_OLD: 'requestUltravioletPage',
  NUBANK_MAIS: 'nubankMaisPage',
};

export async function cmsService({ locale, preview, cache }) {
  const pagesKeys = Object.entries(pages);

  const contentsPromise = await Promise.allSettled(
    pagesKeys
      // eslint-disable-next-line no-unused-vars
      .map(async ([_, pageGetContent]) => pageGetContent({ locale, preview, cache })),
  );

  const contents = pagesKeys.reduce(
    (acc, _, index) => ({
      ...acc,
      ...contentsPromise[index].value,
    }),
    {},
  );

  return {
    cms: {
      ...contents,
    },
  };
}
